import React, { forwardRef } from "react";
import pfp from "../static/pfpSquare.jpg";
import styles from "./About.module.css";
import { SocialIcon } from "react-social-icons";

const About = forwardRef((props, ref) => {
  return (
    <div id="about" ref={ref} className={styles.aboutContainer}>
      <div className={styles.aboutMeContainer}>
        <img
          src={pfp}
          alt="pfp"
          className={styles.pfpShowHide}
          style={{ width: "100%", borderRadius: "10px", marginBottom: "10px" }}
        />
        <div className={styles.name}>Hi, I'm Joseph Lee</div>
        <div className={styles.aboutMeText}>
          <p>
            I’m a software engineer at Capital One in New York City. I was born
            in Pittsburgh but I was raised in Chicago, Yong-in (South Korea),
            and Queens, NY.{" "}
          </p>
          <p>
            I'm interested in machine learning and full-stack web development
            but most importantly I'm passionate about problem solving using
            code.
          </p>
          <p>
            When I'm not in front of my computer, I enjoy playing soccer,
            weightlifting, and playing the drums and guitar.{" "}
          </p>
          <p>
            Welcome to my website and feel free to reach out to me via e-mail
            (josephlee.3150@gmail.com) or linkedin if you have any questions.{" "}
          </p>
          <div className={styles.socialIconShowHide}>
            <SocialIcon
              style={{ marginRight: "10px", height: 35, width: 35 }}
              url="https://github.com/josephlee3150"
              target="_blank"
            />
            <SocialIcon
              style={{ marginRight: "10px", height: 35, width: 35 }}
              url="https://www.linkedin.com/in/joseph-lee-b252211a5/"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default About;
